<template>
  <div class="earlyWarning">
    预警管理
  </div>
</template>

<script>
export default {
  name: "earlyWarning"
}
</script>

<style lang="scss">
.earlyWarning{

}
</style>